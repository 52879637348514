<div class="main" id="main" >
  <lib-gvc-angular-wrapper #gvcviewAngular (componentRef)="onComponentRef($event)" [width]="width" [height]="height"
    [models]="models" [customCommands]="commands" hideMenu="hideMenu" [defaultSettings]="defaultSettings" [home]="home"
    [properties]="properties" [setHome]="setHome" [resetHome]="resetHome" [fit]="fit" [clip]="clip" [measure]="measure"
    [undo]="undo" [redo]="redo" [snapshot]="snapshot" [refresh]="refresh" [navigator]="navigator" [zoomArea]="zoomArea"
    [resources]="resources" [backgroundUrlPath]="backgroundUrlPath" [axisDataPath]="axisDataPath" [hideModelsMenu]="hideModelsMenu"
    [axisFontPath]="axisFontPath" [measureFontPath]="measureFontPath" [measureIconPath]="measureIconPath" [language]="language"
    [crossHairPath]="crossHairPath" [signIn]="signIn" [signOut]="signOut" [userName]="userName" [isolate]="isolate"
    [mainMenuStyle]="mainMenuStyle" mainMenuTitle="GVC-Angular Test App" [fly]="fly" [sheets]="sheets" [levels]="levels" [fenceSelection]="fenceSelection"
    (modelOpened)="handleModelOpened($event)" (modelClosed)="handleModelClosed($event)" (modelStreaming)="handleModelStreaming()" (modelLoaded)="handleModelLoaded($event)" 
    (telemetry)="handleTelemetry($event)" (onLog)="handleLogs($event)" [minLogLevel]="minLogLevel" (viewerLoaded)="handleOnViewerLoaded()" (onHelp)="onHelpHandler()">
  </lib-gvc-angular-wrapper>
</div>
<div class="menubutton" id="menubutton">
    <button  class = "bImage" style="width: 60px; height: 60px; border-radius: 100px; border-width: 0px; background-color: #3d4044"
      (click)="toggleMenu()">
    </button>
</div>

<div id="sidenav" class="sidenav">
  <p>
    <button (click)="handleWorkflow()"> SDx Workflow </button>
  </p>
  <p>
    <button (click)="LoadModel_clicked()"> Load Model</button>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleMainMenuStyleChange()"> Sidebar Style
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handlehideModelsMenu()"> Hide Models Menu
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleRefiningChange()"> Refining Model
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleRefiningOrthoChange()"> Refining Ortho
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleSDxQADemoActiveChange()"> SDx QA Demo
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleRadixTurbineFullChange()"> Turbine Model (SAM/PKCE)
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleRadixPIDActiveChange()"> PID Model (OKTA/PKCE)
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handle2DModelSAMActiveChange()"> 2D Model (SAM)
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleModelStreaming()"> Streaming Events
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleHomeChange()"> Home
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleFitChange()"> Fit
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleClipChange()"> Clip
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleMeasureChange()"> Measure
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleSetHomeChange()"> Set Home
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleResetHomeChange()"> Reset Home
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleUndoChange()"> Undo
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleRedoChange()"> Redo
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleSnapshotChange()" checked> Snapshot
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handlePropertiesChange()"> Properties
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleRefreshChange()"> Refresh
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleFlyChange()"> Fly
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleNavigatorChange()"> Navigator
    </label>
  </p>  
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleZoomAreaChange()"> Zoom Area
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleIsolateChange()"> Isolate
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleAlertCommandChange()"> Alert Command
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleSheetsChange()" checked> Sheets
    </label>
  </p>
   <p>
    <label class="label">
      <input type="checkbox" (click)="handleLayersChange()" checked> Layers
    </label>
  </p>
  <p>
    <label class="label">
      <input type="checkbox" (click)="handleFenceSelectionChange()" checked> Fence Select
    </label>
  </p>

  <p>
    <label className="label" style="color: white">
      <input type="checkbox" (click)="this.toggleSaveLogs()"> Toggle Store GVC Logs
    </label>
  </p>

  <p>
  <label className="label" style="color: white">
  Set Log Level
    <select
      (change)="setGVCLogLevel($event.target.value)"
      [value]="minLogLevel"
    >
      <option value=0>Verbose</option>
      <option value=1>Trace</option>
      <option value=2>Debug</option>
      <option value=3>Info</option>
      <option value=4>Warning</option>
      <option value=5>Error</option>
      <option value=6>Fatal</option>
    </select>
  </label>
</p>