import { NgModule } from '@angular/core';
import { GvcAngularWrapperComponent } from './gvc-angular-wrapper.component';

@NgModule({
  declarations: [GvcAngularWrapperComponent],
  imports: [
  ],
  exports: [GvcAngularWrapperComponent]
})
export class GvcAngularWrapperModule { }
