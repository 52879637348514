import {
    Component,
    AfterViewInit,
    OnDestroy,
} from '@angular/core';
import { GoView } from 'goview2d';

@Component({
    selector: 'goview-app',
    templateUrl: './goview-app.component.html',
    styleUrls: ['./goview-app.component.css']
})

export class GoviewComponent implements AfterViewInit, OnDestroy {
    private _goview2D: GoView;
    private _isMenuOpen = false;
    private _isDrawingAdded = false;

    constructor() {
        document.title = 'Goview 2D';
    }

    /**
     * ngAfterViewInit- Angular lifecycle method called after the view is initialized
     */
    ngAfterViewInit(): void {
        this.initializeGoview();
    }

    /**
     * ngOnDestroy- Angular lifecycle method called on destroy of GoviewComponent
     */
    ngOnDestroy(): void {
        this._goview2D.destroy();
    }

    /**
     * initializeGoview- Is used to create new goview object
     */
    private initializeGoview(): void {
        const container = 'GoViewContainer';
        const resourcePath = window.location.href.substr(0, window.location.href.lastIndexOf('/')) + '/goview-resources/';
        const libsPath = './';

        this._goview2D = new GoView(container, resourcePath, libsPath);
        this._goview2D.addListener('drawingLoaded', () => { document.title = 'Drawing_Loaded'; });
    }

    /**
     * toggleMenu- Is handler for menu button click
     */
    public toggleMenu(): void {
        if (this._isMenuOpen) {
            const menubutton: HTMLElement | null = document.getElementById('menubutton');
            if (menubutton) {
                menubutton.style.right = '50px';
            }
            const sidemenu: HTMLElement | null = document.getElementById('sidenav');
            if (sidemenu) {
                sidemenu.style.right = '-250px';
            }
        } else {
            const menubutton: HTMLElement | null = document.getElementById('menubutton');
            if (menubutton) {
                menubutton.style.right = '200px';
            }
            const sidemenu: HTMLElement | null = document.getElementById('sidenav');
            if (sidemenu) {
                sidemenu.style.right = '0px';
            }
        }
        this._isMenuOpen = !this._isMenuOpen;
    }

    /**
     * handleDrawingLoad- Is handler for load drawing button click
     */
    public handleDrawingLoad(): void {
        const url_path: string = prompt(
            'Please enter url of 2D file',
            'http://inxegoview1/goviewdata/2D/DistillationUnitPID_with_SPIOP_12.02.08/dwg/DistillationUnitPID_with_SPIOP_12.02.08.Vec');
        let featureID = '';
        let featureIDArr: any;
        if (url_path != null && url_path) {
            featureID = prompt('Please enter feature id [Optional]', '');
            if (featureID && featureID != null && featureID !== '') {
                featureIDArr = featureID.split(',');
            }
            if (this._isDrawingAdded) {
                this._goview2D.destroy();
                this.initializeGoview();
                this._isDrawingAdded = false;
            }
            this._goview2D.add2DDrawing(url_path, featureIDArr);
            this._isDrawingAdded = true;
        } else {
            alert('Invalid URL');
        }
    }
}
