<div id="GoViewContainer"></div>
<div>
    <title>GoView Test Portal</title>
</div>
<div class="menubutton" id="menubutton">
    <button class="bImage"
        style="width: 60px; height: 60px; border-radius: 100px; border-width: 0px; background-color: #3d4044"
        (click)="toggleMenu()">
    </button>
</div>
<div id="sidenav" class="sidenav">
    <p>
        <button (click)="handleDrawingLoad()"> Load Drawing </button>
    </p>
</div>