/********************************************************************************************
 * Copyright (C) 2017 Hexagon AB and/or its subsidiaries and affiliates. All rights reserved.
 *
 * File
 * gvc-angular-wrapper.component.tsx
 *
 * Author
 * Aniket Kavlekar
 *
 * Description
 * This component is a wrapper between gvc and angular. App can turn on or off functionalities
 * with the GVC's props settings and can subscribe to the events emmited
 *
 * Notes
 *
 *
 ********************************************************************************************/

import {
  Component,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  AfterViewInit,
  OnChanges,
  OnDestroy,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';

import * as React from 'react';
import * as ReactDOM from 'react-dom';

import {
  GVC, GVCModelObject, GVC_ENUM, GVCPropertyObject, GVCCustomCommand, GVCKey, GVCRange,
  GVCSelection, GVCSelectionChanged,GVCAuthClient,GVCOKTAAuth,GVCSAMAuth,GVCAuthError,GVCAuthStatus,
  GVCLogEvent, GVCAspectGroup
} from '@ppm/gvc-react';

import { MCD_ENUM, FabToolbar } from '@ppm/mcd-utils-js';

@Component({
  selector: 'lib-gvc-angular-wrapper',
  template: `
  <div></div>
  `,
  styleUrls: ['./gvc-angular-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class GvcAngularWrapperComponent implements AfterViewInit, OnChanges, OnDestroy {
  // Properties
  @Input() public width: number;
  @Input() public height: number;
  @Input() public models: GVCModelObject[] | GVCModelObject[][];
  @Input() public customCommands: GVCCustomCommand[] | undefined;
  @Input() public defaultSettings: GVC_ENUM.Settings[];
  @Input() public hideMenu: boolean | undefined;
  @Input() public isSignedIn: boolean | undefined;
  @Input() public userName: string | undefined;
  @Input() public properties: GVCPropertyObject | undefined;
  @Input() public navigator: boolean | undefined;
  @Input() public resources: string | undefined;
  @Input() public backgroundUrlPath: string | undefined;
  @Input() public axisDataPath: string | undefined;
  @Input() public axisFontPath: string | undefined;
  @Input() public measureFontPath: string | undefined;
  @Input() public measureIconPath: string | undefined;
  @Input() public crossHairPath: string | undefined;
  @Input() public language: GVC_ENUM.Language | undefined;
  @Input() public mainMenuStyle: GVC_ENUM.MainMenuStyles | undefined;
  @Input() public mainMenuTitle: string | undefined;
  @Input() public hideModelsMenu: boolean | undefined;
  @Input() public minLogLevel: MCD_ENUM.LogLevel | undefined;
  @Input() public fabToolbar: FabToolbar | undefined;

  // Functions
  @Input() public signIn: () => void | undefined;
  @Input() public signOut: () => void | undefined;
  @Input() public home: () => void | boolean | undefined;
  @Input() public setHome: () => void | boolean | undefined;
  @Input() public resetHome: () => void | boolean | undefined;
  @Input() public fit: () => void | boolean | undefined;
  @Input() public clip: () => void | boolean | undefined;
  @Input() public measure: () => void | boolean | undefined;
  @Input() public redo: () => void | boolean | undefined;
  @Input() public undo: () => void | boolean | undefined;
  @Input() public snapshot: () => void | boolean | undefined;
  @Input() public refresh: () => void | boolean | undefined;
  @Input() public fly: () => void | boolean | undefined;
  @Input() public zoomArea: () => void | boolean | undefined;
  @Input() public isolate: () => void | boolean | undefined;
  @Input() public sheets: () => void | boolean | undefined;
  @Input() public levels: () => void | boolean | undefined;
  @Input() public fenceSelection: () => void | boolean | undefined;
    
  // Events
  @Output() public selectionChanged: EventEmitter<GVCSelectionChanged> = new EventEmitter<GVCSelectionChanged>();
  @Output() public componentRef: EventEmitter<GVC> = new EventEmitter<GVC>();
  @Output() public modelOpened: EventEmitter<GVCModelObject | GVCModelObject[]> = new EventEmitter<GVCModelObject | GVCModelObject[]>();
  @Output() public modelClosed: EventEmitter<GVCModelObject | GVCModelObject[]> = new EventEmitter<GVCModelObject | GVCModelObject[]>();
  @Output() public onStreaming: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onStreamingStopped: EventEmitter<any> = new EventEmitter<any>();
  @Output() public telemetry: EventEmitter<JSON> = new EventEmitter<JSON>();
  @Output() public onLog: EventEmitter<GVCLogEvent> = new EventEmitter<GVCLogEvent>();
  @Output() public viewerLoaded: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onHelp: EventEmitter<any> = new EventEmitter();
  
  private rendered: boolean;

  constructor(private elementRef: ElementRef) {
    this.selectionChangedHandler = this.selectionChangedHandler.bind(this);
    this.modelOpenedHandler = this.modelOpenedHandler.bind(this);
    this.modelClosedHandler = this.modelClosedHandler.bind(this);
    this.onStreamingHandler = this.onStreamingHandler.bind(this);
    this.onStreamingStoppedHandler = this.onStreamingStoppedHandler.bind(this);
    this.telemetryHandler = this.telemetryHandler.bind(this);
    this.onLogHandler = this.onLogHandler.bind(this);
    this.onViewerLoadedHandler = this.onViewerLoadedHandler.bind(this);
    this.onHelpHandler = this.onHelpHandler.bind(this);
  }

  ngAfterViewInit(): void {
    this.render();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.rendered) {
      this.render();
    }
  }

  ngOnDestroy(): void {
    ReactDOM.unmountComponentAtNode(this.elementRef.nativeElement);
  }

  selectionChangedHandler (gvcSelectionChanged: GVCSelectionChanged) {
    this.selectionChanged.emit(gvcSelectionChanged);
  }

  modelOpenedHandler (modelObject: GVCModelObject | GVCModelObject[]) {
    this.modelOpened.emit(modelObject);
  }

  modelClosedHandler (modelObject: GVCModelObject | GVCModelObject[]) {
    this.modelClosed.emit(modelObject);
  }

  onStreamingHandler() {
    this.onStreaming.emit();
  }

  onStreamingStoppedHandler() {
    this.onStreamingStopped.emit();
  }

  telemetryHandler(telemetry: JSON) {
    this.telemetry.emit(telemetry);
  }

  onLogHandler (logEvent: GVCLogEvent) {
    this.onLog.emit(logEvent);
  }

  onViewerLoadedHandler() {
    this.viewerLoaded.emit();
  }

  onHelpHandler()  {
    this.onHelp.emit();
  }

  private render() {
    ReactDOM.render(
      <GVC
        ref={gvcref => {
          this.componentRef.emit(gvcref);
        }}
        width={this.width}
        height={this.height}
        models={this.models}
        customCommands={this.customCommands}
        defaultSettings={this.defaultSettings}
        home={this.home}
        properties={this.properties}
        setHome={this.setHome}
        resetHome={this.resetHome}
        fit={this.fit}
        clip={this.clip}
        measure={this.measure}
        undo={this.undo}
        redo={this.redo}
        snapshot={this.snapshot}
        refresh={this.refresh}
        navigator={this.navigator}
        zoomArea={this.zoomArea}
        resources={this.resources}
        backgroundUrlPath={this.backgroundUrlPath}
        axisDataPath={this.axisDataPath}
        axisFontPath={this.axisFontPath}
        measureFontPath={this.measureFontPath}
        measureIconPath={this.measureIconPath}
        crossHairPath={this.crossHairPath}
        signIn={this.signIn}
        signOut={this.signOut}
        userName={this.userName}
        isolate={this.isolate}
        mainMenuStyle={this.mainMenuStyle}
        mainMenuTitle={this.mainMenuTitle}
        fly={this.fly}
        selectionChanged={this.selectionChangedHandler}
        modelOpened={this.modelOpenedHandler}
        modelClosed={this.modelClosedHandler}
        onStreaming={this.onStreamingHandler}
        onStreamingStopped={this.onStreamingStoppedHandler}
        hideModelsMenu={this.hideModelsMenu}
        language={this.language}
        telemetry={this.telemetryHandler}
        onLog={this.onLogHandler}
        viewerLoaded={this.onViewerLoadedHandler}
        sheets={this.sheets}
        levels={this.levels}
        fenceSelection={this.fenceSelection}
        onHelp={this.onHelpHandler}
        minLogLevel={this.minLogLevel}
        fabToolbar={this.fabToolbar}
      />,
      this.elementRef.nativeElement
    );
    this.rendered = true;
  }
}

export { GVC, GVCModelObject, GVC_ENUM, GVCPropertyObject, GVCCustomCommand, GVCKey, GVCRange, GVCSelection, GVCSelectionChanged, GVCAuthClient, GVCOKTAAuth, GVCSAMAuth, GVCAuthStatus, GVCAuthError, GVCLogEvent, GVCAspectGroup  };
