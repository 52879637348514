import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from './app.component';
import { GvcAppComponent } from './gvc-app/gvc-app.component';
import { GvcAngularWrapperModule } from '@ppm/gvc-angular-wrapper';
import { APP_BASE_HREF } from '@angular/common';
import { GoviewComponent } from './goview-app/goview-app.component';

const appRoutes: Routes = [
  { path: 'Gvc', component: GvcAppComponent },
  { path: 'Goview2D', component: GoviewComponent }
];
// TODO : Below fix is for appending url while gvc-angular page is refresing.This fix is a temporary fix.
const Url = window.location.pathname;
let path = Url;

if (Url.includes('Gvc')) {
  path = Url && Url.indexOf('Gvc') > -1 ? Url.split('Gvc')[0] : Url || '/';
}
if (Url.includes('Goview2D')) {
  path = Url && Url.indexOf('Goview2D') > -1 ? Url.split('Goview2D')[0] : Url || '/';
}
@NgModule({
  declarations: [
    AppComponent, GvcAppComponent, GoviewComponent
  ],
  imports: [
    BrowserModule,
    GvcAngularWrapperModule,
    RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' })
  ],
  providers: [{ provide: APP_BASE_HREF, useValue: path}],
  bootstrap: [AppComponent]
})
export class AppModule { }
