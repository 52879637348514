import { Component, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators'

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit, OnDestroy {
  @ViewChild('homePage') _homePage: any;
  private pageNavigation: Subscription;
  constructor(router: Router) {
    this.pageNavigation = router.events.pipe(
      filter(event => event instanceof NavigationStart)
    ).subscribe((result: NavigationStart) => {
      result.url === "/Gvc" ? this._homePage.nativeElement.style.display = "none" : this._homePage.nativeElement.style.display = "block";
    });
  }
  ngAfterViewInit() { }
  ngOnDestroy() {
    this.pageNavigation.unsubscribe();
  }
}
