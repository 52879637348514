/********************************************************************************************
 * Copyright (C) 2019 Hexagon AB and/or its subsidiaries and affiliates. All rights reserved.
 *
 * File
 * app.component.ts
 *
 * Author
 * Kishore vangapandu
 *
 * Description
 * This component is the application root component to test gvc-angular-wrapper component.
 *
 * Notes
 *
 *
 ********************************************************************************************/

import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { GvcAngularWrapperComponent } from '@ppm/gvc-angular-wrapper';
import {
  GVC, GVCModelObject, GVCKey, GVC_ENUM, GVCPropertyObject, GVCCustomCommand, GVCRange,
  GVCSelection, GVCSelectionChanged, GVCOKTAAuth, GVCSAMAuth, GVCLogEvent
} from '@ppm/gvc-angular-wrapper';
import { MCD_ENUM } from '@ppm/mcd-utils-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'gvc-app',
  templateUrl: './gvc-app.component.html',
  styleUrls: ['./gvc-app.component.css']
})
export class GvcAppComponent implements AfterViewInit {
  public title = 'gvc-angular-wrapper-showcase';
  @ViewChild('gvcviewAngular') public _gvcviewAngular: GvcAngularWrapperComponent;
  private selectionchangedSubscriber: Subscription;
  private streamingStartSubscriber: Subscription;
  private streamingStopSubscriber: Subscription;
  private isMenuOpen = false;
  private gvcRef: GVC;
  private oktaClient : GVCOKTAAuth ;
  private samClient : GVCSAMAuth ;
  public logData: GVCLogEvent[] = [];

  public width: number;
  public height: number;
  public models: GVCModelObject[];
  public commands: GVCCustomCommand[] | undefined;
  public defaultSettings: GVC_ENUM.Settings[];
  public hideMenu: boolean | undefined;
  public userName: string | undefined;
  public properties: GVCPropertyObject | undefined;
  public hideModelsMenu: boolean | undefined;
  // selectionChanged: ((graphicId: number) => any) | undefined;

  public resources: string | undefined;
  public backgroundUrlPath: string | undefined;
  public axisDataPath: string | undefined;
  public axisFontPath: string | undefined;
  public measureFontPath: string | undefined;
  public measureIconPath: string | undefined;
  public crossHairPath: string | undefined;
  public mainMenuStyle: GVC_ENUM.MainMenuStyles | undefined;

  public signIn: Function | undefined;
  public signOut: Function | undefined;
  public home: (Function) | boolean | undefined;
  public setHome: (Function) | boolean | undefined;
  public resetHome: (Function) | boolean | undefined;
  public fit: (Function) | boolean | undefined;
  public clip: (Function) | boolean | undefined;
  public measure: (Function) | boolean | undefined;
  public redo: (Function) | boolean | undefined;
  public undo: (Function) | boolean | undefined;
  public snapshot: (Function) | boolean | undefined;
  public isolate: (Function) | boolean | undefined;
  public refresh: (Function) | boolean | undefined;
  public navigator: (Function) | boolean | undefined;
  public zoomArea: (Function) | boolean | undefined;
  public fly: (Function) | boolean | undefined;
  public sheets: (Function) | boolean | undefined;
  public levels: (Function) | boolean | undefined;
  public fenceSelection: (Function) | boolean | undefined;
  public showGVC: boolean;
  public language: string = 'en-US';
  public minLogLevel: MCD_ENUM.LogLevel;
  public isStoringLogData: boolean;

  private isMainMenuStyleActive: boolean | undefined;
  private alertCommand: GVCCustomCommand;
  private isPropertiesActive: boolean | undefined;
  private isStreamingEventsActive: boolean | undefined;
  private isRefiningActive: boolean | undefined;
  private isRefiningOrthoActive: boolean | undefined;
  private isRadixTurbineFullActive: boolean | undefined;
  private isRadixPIDActive: boolean | undefined;
  private is2DModelSAMActive:boolean | undefined;
  private isSDxQADemoActive: boolean | undefined;
  private isAlertCommandActive: boolean | undefined;

  // Refining
  private refining: GVCModelObject = new GVCModelObject('refining-id',
    'Refining',
    'https://argo.hexagonppm.com/graphicsamples/refining/',
    GVC_ENUM.ModelType.PARAMETRICS,
    GVC_ENUM.AccessProtocol.FILE);

  // Refining Orthographic
  private refiningOrtho: GVCModelObject = new GVCModelObject('refiningortho-id',
    'Refining Ortho',
    'https://argo.hexagonppm.com/graphicsamples/refining/',
    GVC_ENUM.ModelType.PARAMETRICS,
    GVC_ENUM.AccessProtocol.FILE);

 // Radix Turbine -2D Drawing
	private radixTurbineFull: GVCModelObject = new GVCModelObject("radix-turbine-full-id",
			"Radix Turbine Full",
      "",
			GVC_ENUM.ModelType.EDGES,
			GVC_ENUM.AccessProtocol.WEB_API);

  // External -2D Drawing
	private radixPIDFullAuth: GVCModelObject = new GVCModelObject("radix-pid-full-id",
  "Radix PID Full",
  "",
  GVC_ENUM.ModelType.EDGES,
  GVC_ENUM.AccessProtocol.WEB_API);

  // Internal - 2D Drawing
	private drawingSAM : GVCModelObject = new GVCModelObject("radix-pid-sam",
  "Radix PID SAM",
  "https://radixcserver2.ingrnet.com:9085/2DWebAPI/V1/Projects('ProjectC')/Drawings('1011-ENG-PID-M-0001.pid')/",
  GVC_ENUM.ModelType.EDGES,
  GVC_ENUM.AccessProtocol.WEB_API);
  
  private sdxQaDemo: GVCModelObject = new GVCModelObject("sdxqademo-id",
  "3D-SDX QA Demo (GDS)",
  "https://yetis-evidence.ingrnet.com:22419/vds/3d/gds/v1/Plants('SDX1606')/Queries('_DEBUG_Whole')/myVR(Aspect=0,Device=0)",
  GVC_ENUM.ModelType.PARAMETRICS,
  GVC_ENUM.AccessProtocol.WEB_API);
  

  constructor() {
    this.handleModelOpened = this.handleModelOpened.bind(this);
    this.handleModelClosed = this.handleModelClosed.bind(this);
    this.handleOnStreaming = this.handleOnStreaming.bind(this);
    this.handleOnStreamingStopped = this.handleOnStreamingStopped.bind(this);
    this.handleTelemetry = this.handleTelemetry.bind(this);
    this.handleLogs = this.handleLogs.bind(this);
    this.handleOnViewerLoaded = this.handleOnViewerLoaded.bind(this);
    this.handleModelLoaded = this.handleModelLoaded.bind(this);

    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.models = [];
    this.commands = [];
    this.hideMenu = true;
    this.signIn = undefined;
    this.signOut = undefined;
    this.userName = '';
    this.home = false;
    this.setHome = false;
    this.resetHome = false;
    this.fit = false;
    this.clip = false;
    this.measure = false;
    this.redo = false;
    this.undo = false;
    this.hideModelsMenu = false;
    this.snapshot = true;
    this.properties = undefined;
    // this.selectionChanged = undefined;
    this.refresh = false;
    this.navigator = false;
    this.zoomArea = false;
    this.fly = false;
    this.showGVC = false;
    this.isRefiningActive = false;
    this.isRefiningOrthoActive = false;
    this.isRadixTurbineFullActive = false;
    this.isRadixPIDActive = false;
    this.is2DModelSAMActive = false;
    this.isSDxQADemoActive = false;
    this.isPropertiesActive = false;
    this.isStreamingEventsActive = false;
    this.isolate = false;
    this.isAlertCommandActive = false;
    this.isMainMenuStyleActive = false;
    this.sheets = true;
    this.levels = true;
    this.isStoringLogData = false;
    this.fenceSelection = true;
    this.mainMenuStyle = GVC_ENUM.MainMenuStyles.FLOATING_BUTTONS_LEFT;
    this.defaultSettings = [GVC_ENUM.Settings.TRANSPARENCY, GVC_ENUM.Settings.AMBIENT_OCCLUSION, GVC_ENUM.Settings.ANTI_ALIAS];
    this.resources = window.location.href.substr(0, window.location.href.lastIndexOf('/')) + '/gvc-resources/';
    this.gvcRef = undefined;
    this.minLogLevel = MCD_ENUM.LogLevel.Info;
    this.alertCommand = new GVCCustomCommand(
      'alert', 'Show Alert', 'blockage', GVC_ENUM.ModelDimensions.ALL, GVC_ENUM.AccessProtocol.FILE, this.handleAlertCommandCallback);
    this.alertCommand.startupKeys = [new GVCKey(50, GVC_ENUM.KeyEventType.KEY_UP, this.handleAlertCommandCallback)];
    
    this.samClient = new GVCSAMAuth("34618B21-CC39-4E01-AC02-D3F7A29AF8B3","kw=S}-x7eK:@YZhCp#vj9d0S5Cia[$^QqED:J7ver1NiHS%tzQHu=YqFnH7m0e$t",window.location.href.substring(0,window.location.href.lastIndexOf("/") + 1) + "gvcAuthRedirect.html",true);
	  this.radixTurbineFull.webAPIModelType = GVC_ENUM.WEBAPI_MODEL_TYPE.FILE;
		this.radixTurbineFull.authClient = 	this.samClient;
		this.radixTurbineFull.gdsBaseUri = "https://radixcserver2.ingrnet.com:9085/2DWebAPI/V1/";
		this.radixTurbineFull.gdsResourceUri = "Projects('ProjectC')/Drawings('Turbine.igr')";

		this.radixTurbineFull.cameraHeading = GVC_ENUM.CameraHeading.TOP;
		this.radixTurbineFull.cameraProjection = GVC_ENUM.CameraProjection.ORTHOGRAPHIC;
    this.radixTurbineFull.cameraLocks = [ GVC_ENUM.CameraLock.LOCK_YAW, GVC_ENUM.CameraLock.LOCK_PITCH ];
    
    this.oktaClient = new GVCOKTAAuth("0oaoj1vrar4WwHznu0x6",window.location.href.substring(0,window.location.href.lastIndexOf("/") + 1) + "gvcAuthRedirect.html",true);
   	this.radixPIDFullAuth.webAPIModelType = GVC_ENUM.WEBAPI_MODEL_TYPE.FILE;
		this.radixPIDFullAuth.authClient = 	this.oktaClient;
		this.radixPIDFullAuth.gdsBaseUri = "https://hexlive19-radix.hexagonppm.com/2DWebAPI/V1/";
		this.radixPIDFullAuth.gdsResourceUri = "Projects('ProjectC')/Drawings('1011-ENG-PID-M-0001.pid')";

		this.radixPIDFullAuth.cameraHeading = GVC_ENUM.CameraHeading.TOP;
		this.radixPIDFullAuth.cameraProjection = GVC_ENUM.CameraProjection.ORTHOGRAPHIC;
    this.radixPIDFullAuth.cameraLocks = [ GVC_ENUM.CameraLock.LOCK_YAW, GVC_ENUM.CameraLock.LOCK_PITCH ];
    
    this.drawingSAM.cameraHeading = GVC_ENUM.CameraHeading.TOP;
		this.drawingSAM.cameraProjection = GVC_ENUM.CameraProjection.ORTHOGRAPHIC;
		this.drawingSAM.cameraLocks = [ GVC_ENUM.CameraLock.LOCK_YAW, GVC_ENUM.CameraLock.LOCK_PITCH ];
    this.fetchToken(this.drawingSAM);

    this.fetchToken(this.sdxQaDemo);
		this.sdxQaDemo.graphicPropertiesUrl = "https://yetis-evidence.ingrnet.com:22419/vds/3d/gds/";
		this.sdxQaDemo.cameraProjection = GVC_ENUM.CameraProjection.PERSPECTIVE;

 }

  public onComponentRef(gvcRef: GVC) {
    document.title = 'GVC Loaded';
    this.gvcRef = gvcRef;
  }

  ngAfterViewInit() {
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  private updateDimensions() {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
  }

  public handleGVCDisplay(): void {
    this.showGVC = !this.showGVC;
  }

  public handleHomeChange(): void {
    this.home = !this.home;
  }

  public handleSetHomeChange(): void {
    this.setHome = !this.setHome;
  }

  public handleResetHomeChange(): void {
    this.resetHome = !this.resetHome;
  }

  public handleFitChange(): void {
    this.fit = !this.fit;
  }

  public handleClipChange(): void {
    this.clip = !this.clip;
  }

  public handleUndoChange(): void {
    this.undo = !this.undo;
  }

  public handleRedoChange(): void {
    this.redo = !this.redo;
  }

  public handleSnapshotChange(): void {
    this.snapshot = !this.snapshot;
  }

  public handleRefreshChange(): void {
    this.refresh = !this.refresh;
  }

  public handleNavigatorChange(): void {
    this.navigator = !this.navigator;
  }

  public handleZoomAreaChange(): void {
    this.zoomArea = !this.zoomArea;
  }

  private handleModelsUpdatedChanged()
  {
    const modelList: GVCModelObject[] = [];
    if (this.isRefiningActive) {
      modelList.push(this.refining);
    }

    if (this.isRefiningOrthoActive) {
      modelList.push(this.refiningOrtho);
    }

    if (this.isRadixTurbineFullActive) {
      modelList.push(this.radixTurbineFull);
    }

    if (this.isRadixPIDActive) {
      modelList.push(this.radixPIDFullAuth);
    }
    
    if (this.is2DModelSAMActive) {
      modelList.push(this.drawingSAM);
    }

    if (this.isSDxQADemoActive) {
      modelList.push(this.sdxQaDemo);
    }

    this.models = modelList;
  }
  public handleRefiningChange(): void {
    this.isRefiningActive = !this.isRefiningActive;
    this.handleModelsUpdatedChanged();
  }

  public handleRefiningOrthoChange(): void {
    this.isRefiningOrthoActive = !this.isRefiningOrthoActive;
    this.handleModelsUpdatedChanged();
  }

  public handleRadixTurbineFullChange(): void {
    this.isRadixTurbineFullActive = !this.isRadixTurbineFullActive;
    this.handleModelsUpdatedChanged();
  }
  
  public handleRadixPIDActiveChange(): void {
    this.isRadixPIDActive = !this.isRadixPIDActive;
    this.handleModelsUpdatedChanged();
  }

  public handle2DModelSAMActiveChange(): void {
    this.is2DModelSAMActive = !this.is2DModelSAMActive;
    this.handleModelsUpdatedChanged();
  }

  public handleSDxQADemoActiveChange(): void {
    this.isSDxQADemoActive = !this.isSDxQADemoActive;
    this.handleModelsUpdatedChanged();
  }
  
  public handleIsolateChange(): void {
    this.isolate = !this.isolate;
  }

  public handleSheetsChange(): void {
    this.sheets = !this.sheets;
  }

  public handleMainMenuStyleChange(): void {
    this.isMainMenuStyleActive = !this.isMainMenuStyleActive;
    if (this.isMainMenuStyleActive) {
      this.mainMenuStyle = GVC_ENUM.MainMenuStyles.SIDEBAR_FLOATING_BUTTONS_LEFT;
    } else {
      this.mainMenuStyle = GVC_ENUM.MainMenuStyles.FLOATING_BUTTONS_LEFT;
    }
  }

  public handlehideModelsMenu(): void {
    this.hideModelsMenu = !this.hideModelsMenu;
  }

  public handleMeasureChange(): void {
    this.measure = !this.measure;
  }

  public handleFlyChange(): void {
    this.fly = !this.fly;
  }
  
  public handleLayersChange(): void {
    this.levels = !this.levels;
  }

  public handleFenceSelectionChange(): void {
    this.fenceSelection = !this.fenceSelection;
  }

	public setGVCLogLevel(level: any) {
		this.minLogLevel = Number(level);
	}

	private downloadLogs(): void {
		let results = "Component,Location,LogLevel,LogCode,Message\n"; // insert the header
		// if turning off, save any logs recorded
		for(let i = 0; i < this.logData.length; i++) {
			results += this.logData[i].toString().trim() + "\n";
		}
		this.logData = []; // clear logs
		const element = document.createElement("a");
		const file = new Blob([ results ], {type: "text/plain"});
		element.href = URL.createObjectURL(file);
		element.download = "GVCLogs.csv";
		document.body.appendChild(element);
		element.click();
	}

	public toggleSaveLogs(): void {
		if(this.isStoringLogData) {
			this.downloadLogs();
		}
		this.isStoringLogData = !this.isStoringLogData;

	}

  public handlePropertiesChange(): void {
    this.isPropertiesActive = !this.isPropertiesActive;
    if (this.isPropertiesActive) {
      this.selectionchangedSubscriber = this._gvcviewAngular.selectionChanged.subscribe(this.onSelectionChanged.bind(this));
    } else {
      this.selectionchangedSubscriber.unsubscribe();
    }
  }

  public handleAlertCommandChange(): void {
    this.isAlertCommandActive = !this.isAlertCommandActive;
    const commands: GVCCustomCommand[] = [];
    if (this.isAlertCommandActive) {
      commands.push(this.alertCommand);
    }
    this.commands = commands;
  }

  public handleAlertCommandCallback(): void {
    alert('An alert has been triggered.');
  }

  public toggleMenu(): void {
    if (this.isMenuOpen) {
      const menubutton: HTMLElement | null = document.getElementById('menubutton');
      if (menubutton) {
        menubutton.style.right = '50px';
      }
      const sidemenu: HTMLElement | null = document.getElementById('sidenav');
      if (sidemenu) {
        sidemenu.style.right = '-250px';
      }
      const mainscreen: HTMLElement | null = document.getElementById('main');
      if (mainscreen) {
        mainscreen.style.right = '250px';
      }
    } else {
      const menubutton: HTMLElement | null = document.getElementById('menubutton');
      if (menubutton) {
        menubutton.style.right = '200px';
      }
      const sidemenu: HTMLElement | null = document.getElementById('sidenav');
      if (sidemenu) {
        sidemenu.style.right = '0px';
      }
      const mainscreen: HTMLElement | null = document.getElementById('main');
      if (mainscreen) {
        mainscreen.style.right = '0px';
      }
    }
    this.isMenuOpen = !this.isMenuOpen;
  }

  public LoadModel_clicked() {
    const model: any = {};
    const model_name = prompt('Please enter model name', 'DistillationUnit');
    if (model_name != null && model_name) {
      model.displayName = model_name;
      const url_path = prompt('Please enter url of 3D file', 'http://inxegoview1/goviewdata/3D/DistillationUnit0512_with_SPIOP_13.00.48/');
      if (url_path != null && url_path) {
        model.url = url_path;
        model.protocol = 'file';
        model.selection = '';
        // document.title = "Model Loading Started";
        // this.onClickUrl(model);
        const modelList: GVCModelObject[] = [];
        const currentModel: GVCModelObject = new GVCModelObject(
          model.displayName + '-id', model.displayName, model.url, GVC_ENUM.ModelType.PARAMETRICS, GVC_ENUM.AccessProtocol.FILE);
        modelList.push(currentModel);
        this.models = modelList;
      }
    }
  }
  /**
   * This method handles SDx workflow which involves loading model and selection with one button click
   */
  public handleWorkflow() {
    const model: any = {};
    const model_name = prompt('Please enter model name', 'DistillationUnit');
    if (model_name != null && model_name) {
      model.displayName = model_name;
      const url_path = prompt('Please enter url of 3D file', 'http://inxegoview1/goviewdata/3D/DistillationUnit0512_with_SPIOP_13.00.48/');
      if (url_path != null && url_path) {
        model.url = url_path;
        model.protocol = 'file';
        model.selection = '';
        const selectionID: string = prompt('Enter ID for selection', '1658');
        const selectedObjRange: string = prompt('Enter selected object range', '3035.85,2362.5,68.66,3037.85,2364.5,73.26');
        if ((selectionID != null && selectionID) && (selectedObjRange != null && selectedObjRange)) {
          const selectedRange = (selectedObjRange.split(','));
          const mcdRange: GVCRange = new GVCRange();
          mcdRange.minx = parseFloat(selectedRange[0]);
          mcdRange.miny = parseFloat(selectedRange[1]);
          mcdRange.minz = parseFloat(selectedRange[2]);
          mcdRange.maxx = parseFloat(selectedRange[3]);
          mcdRange.maxy = parseFloat(selectedRange[4]);
          mcdRange.maxz = parseFloat(selectedRange[5]);
          const selection: GVCSelection = new GVCSelection(parseInt(selectionID), mcdRange);
          const selectionList: GVCSelection[] = [];
          selectionList.push(selection);

          const modelList: GVCModelObject[] = [];
          document.title = 'Model Loading Started';
          const modelObj: GVCModelObject = new GVCModelObject(
            model.displayName + '-id', model.displayName, model.url, GVC_ENUM.ModelType.PARAMETRICS, GVC_ENUM.AccessProtocol.FILE);
          modelList.push(modelObj);
          modelObj.selectionIds = selectionList;
          this.models = modelList;

          // Below code gets resources (objects) rendered information and once all the objects are rendered, title of testapp changes
          this.gvcRef.addResourceLoadEvent((resourceLoad) => {
            if (resourceLoad !== undefined && resourceLoad.hasOwnProperty('event')) {
              if (resourceLoad['event'] === 'resource-cached') {
                document.title = 'Object Selected and Fitted';
              }
            }
          });
        }
      }
    }
  }

  private onSelectionChanged(gvcSelectionChanged: GVCSelectionChanged): void {
    if (gvcSelectionChanged.graphicId !== -1) {
      const propertyTest: GVCPropertyObject = new GVCPropertyObject(
        'Test',
        {
          'TEST1': { 'Selected Object ID': gvcSelectionChanged.graphicId.toString() },
          'TEST2': { 'Selected Object ID': gvcSelectionChanged.graphicId.toString() }
        }, []);
      this.properties = propertyTest;
    } else {
      this.properties = undefined;
    }
  }

  // TODO : handle this event handler based on what is needed for FT
  public handleModelOpened(model: GVCModelObject | GVCModelObject[]): void {
    setTimeout(() => { document.title = 'ModelOpened Event Fired'; }, 3000);
  }

  public handleModelClosed(model: GVCModelObject | GVCModelObject[]): void {
    document.title = 'ModelClosed Event Fired';
  }

  public handleModelStreaming(): void {
    this.isStreamingEventsActive = !this.isStreamingEventsActive;
    if (this.isStreamingEventsActive) {
      this.streamingStartSubscriber = this._gvcviewAngular.onStreaming.subscribe(this.handleOnStreaming.bind(this));
      this.streamingStopSubscriber = this._gvcviewAngular.onStreamingStopped.subscribe(this.handleOnStreamingStopped.bind(this));
    } else {
      this.streamingStartSubscriber.unsubscribe();
      this.streamingStopSubscriber.unsubscribe();
    }
  }

  private handleOnStreaming(): void {
    setTimeout(() => { document.title = 'OnStreaming Event Fired'; }, 4000);
  }

  private handleOnStreamingStopped(): void {
    setTimeout(() => { document.title = 'OnStreamingStopped Event Fired'; }, 4100);
  }

  // Used to make sure events were firing correctly
  public handleTelemetry(json: JSON) { }

  // Used to make sure events were firing correctly
	public handleLogs(log: GVCLogEvent): void{
		if(log.level === MCD_ENUM.LogLevel.Warn) {
			console.warn(log);
		}
		else if(log.level === MCD_ENUM.LogLevel.Error) {
			console.error(log);
		}
		else {
			/* eslint-disable */
			console.log(log);
			/* eslint-enable */
		}
    if(this.isStoringLogData) {
			this.logData.push(log);
    }
	}

  //used to make sure the mcdview is loaded
  public handleOnViewerLoaded(): void {
    setTimeout(() => { document.title = 'OnViewerLoaded Event Fired'; }, 6000);
  }

  //handler for modelLoaded
  public handleModelLoaded(evt) {}

  public onHelpHandler()
  {
    window.open("https://hexagonppm-uat.fluidtopics.net/reader/mwtLCO_q_M07J20LovEQcw/LnJ6OD6XbmK1W~UuhMTX6g");
  }

  	// Used to test 2D and 3D APIs for auth. In a normal situation, this request would not be made,
	// and authentication would be performed through a standard login page which would get the access token.
	// Could be expanded in the future beyond drawings.
	private async fetchToken(tokenTarget: GVCModelObject ): Promise<void> {
		if (tokenTarget.modelType === GVC_ENUM.ModelType.EDGES) {
			const headers: HeadersInit = new Headers();
			headers.set("cache-control","no-cache");
			headers.set("Connection","keep-alive");
			headers.set("Content-Type", "application/x-www-form-urlencoded");
			await fetch("https://samdev.ingrnet.com/sam/oauth/connect/token", {
				body: "grant_type=password&username=devrad&password=BrickTeam.13.&client_id=CD298E83-9E5F-4211-96CB-3D5A713E5676&client_secret=I9trEHCe_R%40%5Bm%2A2bYV%2By_D%24mN%2AZ%29%5E4mHGH%2F%28Rp5mQi%3F6I%2BRtpAotp%2FU%24h3ekoHKS&scope=7EEBB625-E80C-4AF5-8A37-605CCB73A15D",
				headers,
				method: "POST"
			}).then(data=>data.json()).then(data => {
				console.warn("Edges SAM Token Obtained: " + data.access_token);
				tokenTarget.authorizationToken = data.access_token;
			});
		}
		else if (tokenTarget.modelType === GVC_ENUM.ModelType.PARAMETRICS) {
			const headers: HeadersInit = new Headers();
			headers.set("cache-control","no-cache");
			headers.set("Connection","keep-alive");
			headers.set("Content-Type", "application/x-www-form-urlencoded");
			await fetch("https://samdev.ingrnet.com/sam/oauth/connect/token", {
				body: "grant_type=password&username=GDSUser&password=GDSUser.0&client_id=2504D9E5-B1C0-4B05-BFFF-F046632A5A5D&client_secret=DjsTDu%3AYyi%7DD%2BxKe5%7BC%5DV_V%253tR%2AO6CvekVSgvt%3Bf26m%28%5BktWpC%24hIfLdWIFO%5Bt%24&scope=9FC64CDE-6C23-4368-B19F-172D5736A2BA",
				headers,
				method: "POST"
			}).then(data=>data.json()).then(data => {
				console.warn("Parametrics SAM Token Obtained: " + data.access_token);
				tokenTarget.authorizationToken = data.access_token;
			});
		}
	}
}
